/*
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-30 16:55:56
 * @LastEditors: 沈为
 * @LastEditTime: 2022-07-31 15:15:52
 */
import {createRouter, createWebHistory} from 'vue-router'
import loginPage from "@/pages/loginPage";
import theFrame from "@/pages/theFrame";
const routes = [
  {
    name: 'loginPage',
    path: '/',
    component: loginPage
  },
  {
    name: 'theFrame',
    path: '/theFrame',
    component: theFrame
  },
]

const router = createRouter({
    history: createWebHistory(), 
    routes
})

export default router
