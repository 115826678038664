<!--
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-30 16:13:47
 * @LastEditors: 赵卓轩
 * @LastEditTime: 2022-08-14 18:05:47
-->
<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <div class="top">
        <img class="logo" src="@/assets/logo.png" />
        <div class="title" v-if="!collapsed">NFT纪元中台系统</div>
      </div>
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-menu-item :key="1">
          <team-outlined />
          <span>权限分配</span>
        </a-menu-item>
        <a-sub-menu :key="sub1">
          <template #title>
            <span>
              <dropbox-square-filled />
              <span>藏品操作</span>
            </span>
          </template>
          <a-menu-item :key="2">藏品管理</a-menu-item>
          <a-menu-item :key="7">盲盒管理</a-menu-item>
          <a-menu-item :key="3">藏品回收与冷却</a-menu-item>
        </a-sub-menu>
        <a-sub-menu :key="sub2">
          <template #title>
            <span>
              <wallet-filled />
              <span>用户管理</span>
            </span>
          </template>
          <a-menu-item :key="4">查询购买记录</a-menu-item>
          <a-menu-item :key="5">查询转赠记录</a-menu-item>
          <a-menu-item :key="6">积分管理</a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-button style="float: left;margin:15px" @click="checkHelp()">操作手册</a-button>
        <div class="info">
          <span style="margin-right:20px">管理员账号:{{ this.phone }}</span>
          <a-button type="danger" @click="logOut()">退出登录</a-button>
        </div>
      </a-layout-header>
      <a-layout-content style="margin: 16px 0">
        <transition name="fade" mode="out-in">
          <a-typography-text strong style="margin: 0 16px" :key="tabs[selectedKeys[0] - 1].name">
            {{ tabs[selectedKeys[0] - 1].name }}
          </a-typography-text>
        </transition>
        <div :style="{ margin: '24px', padding: '24px', background: '#fff', minHeight: '360px' }">
          <transition name="fade" mode="out-in">
            <div style="height:100%;width:100%" :key="tabs[selectedKeys[0] - 1].name">
              <permission-manage v-if="selectedKeys[0] === 1"></permission-manage>
              <drop-goods v-if="selectedKeys[0] === 2"></drop-goods>
              <recycle-goods v-if="selectedKeys[0] === 3"></recycle-goods>
              <transaction-log v-if="selectedKeys[0] === 4"></transaction-log>
              <transaction-to-user-log v-if="selectedKeys[0] === 5"></transaction-to-user-log>
              <MarketScoreManage v-if="selectedKeys[0] === 6"></MarketScoreManage>
              <BlindBoxManage v-if="selectedKeys[0] === 7"></BlindBoxManage>
            </div>
          </transition>
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        NFT era ©2022 Created by NFT era
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import {
  TeamOutlined,
  WalletFilled,
  DropboxSquareFilled
} from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';
import tokenUtils from '@/config/tokenUtils.js'
import global from "@/config/global.js"
import { message } from 'ant-design-vue';

import permissionManage from './components/permissionManage.vue';
import dropGoods from './components/dropGoods.vue';
import recycleGoods from './components/recycleGoods.vue';
import transactionLog from './components/transactionLog.vue';
import transactionToUserLog from './components/transactionToUserLog.vue';
import DropGoods from './components/dropGoods.vue';
import RecycleGoods from './components/recycleGoods.vue';
import TransactionLog from './components/transactionLog.vue';
import TransactionToUserLog from './components/transactionToUserLog.vue';
import MarketScoreManage from './components/marketScoreManage.vue';
import BlindBoxManage from './components/blindBoxManage.vue';

let timer = null;

export default {
  components: {
    TeamOutlined,
    WalletFilled,
    DropboxSquareFilled,
    permissionManage,
    dropGoods,
    recycleGoods,
    transactionLog,
    transactionToUserLog,
    DropGoods,
    RecycleGoods,
    TransactionLog,
    TransactionToUserLog,
    MarketScoreManage,
    BlindBoxManage
},
  data() {
    return {
      selectedKeys: [1],
      collapsed: false,
      phone: '',
      name: '',
      tabs: [
        {
          name: '权限分配',

        },
        {
          name: '藏品空投',

        },
        {
          name: '藏品回收',

        },
        {
          name: '查询购买记录',

        },
        {
          name: '查询转赠记录',
        },
        {
          name: '商城积分管理'
        },
        {
          name: '盲盒管理'
        }
      ]
    };
  },

  mounted() {
    this.phone = localStorage.getItem("phone")
    this.name = localStorage.getItem("name")
    //定时执行刷新token
		timer = setInterval(() => {
			//需要定时执行的代码
			this.refreshToken()
		}, 600000)
  },

  methods: {
    logOut(){
      tokenUtils.removeToken();
      this.$router.push(
        {
          name: 'loginPage'
        }
      )
      message.info("请登录");
    },
    checkHelp(){
      console.log("打开操作手册")
      message.success("还没写")
    },
    /**
		 * 刷新token，要是本地token已过期，则返回登录页
		 */
		refreshToken() {
      console.log("刷新token")
      this.axios({
          method: "get",
          url: global.baseUrl + "/v1/api/login/refresh",
          headers: {
            'Authorization': 'Bearer ' + tokenUtils.getToken()
          }
        }).then((res) => {
          if (res.status === 200) {
            tokenUtils.setToken(res.data.data.token)
          }
        }).catch((res) => {
          this.$router.push(
            {
              name: 'loginPage'
            }
          )
        })
		},
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  display: flex;
}

.top {
  height: 46px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.logo {
  float: left;
  width: 46px;
  height: 46px;
  /* margin: 6px 24px 6px 0; */
  /* background: rgba(255, 255, 255, 0.3); */
}

.title {
  position: absolute;
  left: 65px;
  width: 120px;
  margin: 13px 0 0 0;
  color: white;
}

.info {
  float: right;
  margin-right: 20px;
  margin-left: 20px;
  color: black;
}

/* 菜单的淡入淡出效果 */
/* .fade-enter{

} */
.fade-enter-active{
  /* 设置过渡效果何时开始 */
  transition-delay: .25s;
}
/* .fade-enter-to{

} */
/* .fade-leave {

} */
.fade-leave-active {
  /* 设置过渡效果的CSS属性名称 */
  transition-property: opacity margin;
  /* 设置过渡效果需要的时间 */
  transition-duration: .5s;
  opacity: 0;
  margin-top: 50px;
}
.fade-leave-to {
  /* 设置过渡效果的CSS属性名称 */
  transition-property: opacity margin;
  /* 设置过渡效果需要的时间 */
  transition-duration: .5s;
}
</style>
