<!--
 * @Description: 
 * @version: 1.0
 * @Author: 赵卓轩
 * @Date: 2022-08-14 18:04:02
 * @LastEditors: 赵卓轩
 * @LastEditTime: 2022-08-14 23:00:22
-->
<template>
    <a-table :columns="columns" :data-source="blindBoxList" :loading="loading">
        <template #headerCell="{ column }">
            <template v-if="column.key === 'id'">
                <span>
                    盲盒编号
                </span>
            </template>
        </template>

        <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
                <span>
                    {{ record.id }}
                </span>
            </template>
            <template v-if="column.key === 'name'">
                <span>
                    {{ record.name }}
                </span>
            </template>
            <template v-else-if="column.key === 'imgUrl'">
                <a-image style="width:80px;height:auto" :src="record.imgUrl"></a-image>
            </template>
            <template v-else-if="column.key === 'introduction'">
                {{ record.introduction }}
            </template>
            <template v-if="column.key === 'total'">
                <span>
                    {{ record.total }}
                </span>
            </template>
            <template v-if="column.key === 'remain'">
                <span>
                    {{ record.remain }}
                </span>
            </template>
            <template v-else-if="column.key === 'action'">
                <a-button @click="dropBlindBox(record.id)" type="primary" ghost size="small">空投盲盒</a-button>
            </template>
        </template>
    </a-table>
    <a-modal v-model:visible="showDrop" title="提示" cancelText="算了" okText="确认空投" :confirm-loading="confirmLoading"
        @ok="doDrop">
        <div>
            发往用户手机号：
            <a-input class="input" v-model:value.lazy="phone" placeholder="手机号" />
        </div>
        <div>
            选择藏品及序号：
            <a-cascader v-model:value="value" :options="blindBoxGiftList" :load-data="loadData" placeholder="请选择藏品"
                change-on-select @change="onSelectFinished"/>
        </div>
    </a-modal>
</template>

<script>
import tokenUtils from "@/config/tokenUtils.js";
import global from "@/config/global.js";
import { message } from "ant-design-vue";
const columns = Object.freeze([
    {
        name: '盲盒编号',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '图片',
        dataIndex: 'imgUrl',
        key: 'imgUrl',
    },
    {
        title: '介绍',
        key: 'introduction',
        dataIndex: 'intrudction',
    },
    {
        title: '总量',
        key: 'total',
        dataIndex: 'total',
    },
    {
        title: '余量',
        key: 'remain',
        dataIndex: 'remain',
    },
    {
        title: '操作',
        key: 'action',
    },
]);

export default {
    name: 'blindBoxManage',
    data() {
        return {
            columns: columns,
            blindBoxList: [],
            blindBoxGiftList: [],
            blindBoxId: 0,
            loading: false,
            phone: '',
            showDrop: false,
            confirmLoading: false,
            goodsId: -1,
            sequenceNumber: -1
        }
    },
    mounted() {
        this.loading = true;
        this.axios({
            method: "get",
            url: global.baseUrl + `/v1/api/admin/goods/allGoods/`,
            headers: {
                Authorization: "Bearer " + tokenUtils.getToken(),
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    res.data.data.forEach((item, index) => {
                        item.goods.goodsType === 'BlindBox' && this.blindBoxList.push({
                            key: index,
                            id: item.goods.id,
                            name: item.goods.name,
                            imgUrl: item.imgUrl,
                            introduction: item.goods.introduction,
                            total: item.goods.total,
                            remain: item.goods.remain
                        });
                        item.goods.goodsType === 'BlindBoxGoods' && this.blindBoxGiftList.push({
                            value: item.goods.id,
                            label: item.goods.name,
                            isLeaf: false
                        })
                    })
                    this.loading = false;
                } else {
                    message.warning(res.data.message);
                    this.loading = false;
                }
            })
            .catch((res) => {
                message.warning(res.response.data.message);
                this.loading = false;
            });
    },
    methods: {
        onSelectFinished(e) {
            if(e.length === 2) {
                this.goodsId = e[0];
                this.sequenceNumber = e[1];
            }
        },
        doDrop() {
            if(!(this.phone && this.sequenceNumber >0 && this.goodsId > 0)) {
               message.warning("请完善表单信息");
                return;
            }
            this.confirmLoading = true;
            this.axios({
                method: "post",
                url: global.baseUrl + `/v1/api/admin/goods/dropGoodsWithBlindBox?blindBoxId=${this.blindBoxId}&goodsId=${this.goodsId}&phone=${this.phone}&sequenceNumber=${this.sequenceNumber}`,
                headers: {
                    Authorization: "Bearer " + tokenUtils.getToken(),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    this.showDrop = false;
                    this.confirmLoading = false;
                    message.success("空投成功");
                } else {
                    message.warning(res.data.message);
                    this.confirmLoading = false;
                }
            })
            .catch((res) => {
                message.warning(res.response.data.message);
                this.confirmLoading = false;
            });
        },
        dropBlindBox(blindBoxId) {
            this.blindBoxId = blindBoxId;
            this.phone = '';
            this.sequenceNumber = -1;
            this.goodsId = -1;
            this.showDrop = true;
        },
        async loadData(selectedOptions) {
            const targetOption = selectedOptions[selectedOptions.length - 1];
            targetOption.loading = true; // load options lazily
            try{
                const existNumbers = await this.getExistNumber(targetOption.value);
                const existMap = new Map();
                existNumbers.data.data.forEach(item => {
                    existMap.set(item, 1);
                })
                const notExistNumbers = await this.getNotExistNumber(targetOption.value);
                const availableNumbers = notExistNumbers.data.data.filter(item => !existMap.has(item));
                targetOption.children = availableNumbers.map(item => {
                    return {
                        label: item,
                        value: item
                    }
                })
                this.blindBoxGiftList = [...this.blindBoxGiftList];
            // eslint-disable-next-line no-empty
            } catch(err) {
                message.warning(err);
            }
        },
        getExistNumber(goodsId) {
            return this.axios({
                method: "get",
                url: global.baseUrl + `/v1/api/admin/goods/sequenceNumber/exist/${goodsId}`,
                headers: {
                    Authorization: "Bearer " + tokenUtils.getToken(),
                },
            })
        },
        getNotExistNumber(goodsId) {
            return this.axios({
                method: "get",
                url: global.baseUrl + `/v1/api/admin/goods/sequenceNumber/notExist/${goodsId}`,
                headers: {
                    Authorization: "Bearer " + tokenUtils.getToken(),
                },
            })
        }
    }
}
</script>

<style scoped>
.input {
  height: 30px;
  width: 250px;
  margin: 20px;
}
</style>