<!--
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-31 15:13:46
 * @LastEditors: 赵卓轩
 * @LastEditTime: 2022-08-14 16:30:11
-->
<template>
  <div class="content">
    <div style="margin:20px">
      输入用户手机号：
      <a-input class="input" v-model:value="phoneInput" placeholder="手机号" />
      <a-button type="primary" @click="getLog()">查询</a-button>
    </div>
    <div class="goodsList">
      <div>
        <a-table :loading="loading" :pagination="false" :columns="columns" :data-source="transactionLogList" :scroll="{ y: 500 }">
          <template #headerCell="{  }">
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              {{ record.id }}
            </template>
            <template v-if="column.key === 'goodsId'">
              {{ record.goodsId }}
            </template>
            <template v-if="column.key === 'marketGoodsId'">
              {{ record.marketGoodsId }}
            </template>
            <template v-if="column.key === 'name'">
              {{ record.name }}
            </template>
            <template v-if="column.key === 'transactionStatus'">
              {{ record.transactionStatus }}
            </template>
            <template v-if="column.key === 'createTime'">
              {{ record.createTime }}
            </template>          
            <template v-if="column.key === 'updateTime'">
              {{ record.updateTime }}
            </template>
            <template v-if="column.key === 'price'">
              {{ record.price }}
            </template>
            <template v-if="column.key === 'platformPrice'">
              {{ record.platformPrice }}
            </template>
            <template v-if="column.key === 'thirdPrice'">
              {{ record.thirdPrice }}
            </template>
            <template v-if="column.key === 'authorPrice'">
              {{ record.authorPrice }}
            </template>
            <template v-if="column.key === 'userId'">
              {{ record.userId }}
            </template>
            <template v-if="column.key === 'userPrice'">
              {{ record.userPrice }}
            </template>
          </template>
        </a-table>
        <a-button type="link" :disabled="loading" @click="loadMore()" v-if="Page.ifHave">加载更多</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import tokenUtils from '@/config/tokenUtils.js'
import global from "@/config/global.js"
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      transactionLogList: [],
      phoneInput: '',
      phone: '',
      loading: false,
      columns: [
        {
          title: '订单编号',
          key: 'id',
        },
        {
          title: '藏品编号',
          key: 'goodsId',
        },
        {
          title: '商城商品编号',
          key: 'marketGoodsId',
        },
        {
          title: '名称',
          key: 'name',
        },
        {
          title: '订单状态',
          key: 'transactionStatus',
        },
        {
          title: '创建时间',
          key: 'createTime',
        },
        {
          title: '状态更新时间',
          key: 'updateTime',
        },
        {
          title: '订单价格',
          key: 'price',
        },
        {
          title: '平台服务费',
          key: 'platformPrice',
        },
        {
          title: '第三方支付抽成',
          key: 'thirdPrice',
        },
        {
          title: '创作者抽成',
          key: 'authorPrice',
        },
        {
          title: '订单归属用户编号',
          key: 'userId'
        },
        {
          title: '用户实际所得',
          key: 'userPrice'
        }
      ],
      Page: {
        page: 0,
        size: 10,
        ifHave: true
      }
    };
  },

  mounted() {

  },

  methods: {
    /**
     * 查询手机号对应的交易记录
     */
    getLog() {
      console.log("查询交易记录")
      if (this.phoneInput.length !== 11) {
        message.info("非法手机号")
        return;
      }
      this.Page.page = 0
      this.Page.ifHave = true
      this.transactionLogList = []
      this.phone = this.phoneInput
      this.getTransactionLog()
    },
    getTransactionLog() {
      console.log("查询交易记录")
      if (this.phone.length !== 11) {
        message.info("非法手机号")
        return;
      }
      if (!this.Page.ifHave) {
        return;
      }
      this.loading = true
      //发送请求
      this.axios({
        method: "get",
        url: global.baseUrl + "/v1/api/admin/goods/transactionLog/" + this.phone + "/" + this.Page.page + "/" + this.Page.size,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log("查询成功");
        console.log(res);
        if (res.status === 200) {
          if (res.data.data.length < this.Page.size) {
            this.Page.ifHave = false
          }
          this.transactionLogList = this.transactionLogList.concat(res.data.data)
          this.loading = false
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.loading = false
      })
    },
    loadMore(){
      console.log("触底")
      if(!this.Page.ifHave){
        message.info("没有更多了")
        return;
      }
      this.Page.page += 1;
      this.getTransactionLog();
    }
  },

}
</script>

<style scoped>
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.goodsList {
  height: auto;
  /* max-height: 500px; */
  margin-bottom: 50px;
  width: 100%;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
  scrollbar-width: none;
}

/*滚动条样式*/
.goodsList::-webkit-scrollbar {
  width: 4px;
}

.goodsList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.goodsList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.input {
  height: 30px;
  width: 250px;
  margin: 20px;
}
</style>