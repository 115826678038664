<!--
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-31 15:14:01
 * @LastEditors: 沈为
 * @LastEditTime: 2022-08-02 10:58:06
-->
<template>
  <div class="content">
    <div style="margin:20px">
      输入用户手机号：
      <a-input class="input" v-model:value="phoneInput" placeholder="手机号" />
      <a-button type="primary" @click="getLog()">查询</a-button>
    </div>
    <div class="goodsList">
      <a-divider>查询转赠记录</a-divider>
      <div>
        <a-table :loading="loading" :pagination="false" :columns="columns" :data-source="transactionToUserList">
          <template #headerCell="{ column }">
            <template v-if="column.key === 'id'">
              <span>
                藏品编号
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              {{ record.id }}
            </template>
            <template v-if="column.key === 'imageUrl'">
              <a-image style="width:80px;height:auto" :src="record.goodsImgUrl"></a-image>
            </template>
            <template v-if="column.key === 'name'">
              {{ record.goodsName }}
            </template>
            <template v-if="column.key === 'userName'">
              {{ record.userName }}
            </template>
            <template v-if="column.key === 'phone'">
              {{ record.phone }}
            </template>
            <template v-if="column.key === 'dateTime'">
              {{ record.dateTime }}
            </template>
            <template v-if="column.key === 'action'">
              <span>
                <a-button @click="cancelTrans(record)" type="danger" ghost>撤销转赠</a-button>
              </span>
            </template>
          </template>
        </a-table>
      </div>
      <a-modal v-model:visible="showConfirmCancel" title="提示" cancelText="算了" okText="撤销！"
        :confirm-loading="confirmLoading" @ok="doCancel()">
        <p>撤消转赠藏品名称：<a-typography-text mark>{{ this.cancelGoods.name }}</a-typography-text>
        </p>
        <p>用户手机号：<a-typography-text mark>{{ this.phone }}</a-typography-text>
        </p>
        <p>返还手机号：<a-typography-text mark>{{ this.cancelGoods.phone }}</a-typography-text>
        </p>
        <p>转赠编号：<a-typography-text type="danger">{{ this.cancelGoods.id }}</a-typography-text>
        </p>
        <p>
          <a-typography-text strong>撤销转赠为重要操作，请谨慎核对，确认撤销？</a-typography-text>
        </p>
      </a-modal>
      <a-button type="link" :disabled="loading" @click="loadMore()" v-if="Page.ifHave">加载更多</a-button>
    </div>
  </div>
</template>

<script>
import tokenUtils from '@/config/tokenUtils.js'
import global from "@/config/global.js"
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      phoneInput: '',
      phone: '',
      transactionToUserList: [],
      cancelGoods: '',
      loading: false,
      confirmLoading: false,
      showConfirmCancel: false,
      Page: {
        page: 0,
        size: 10,
        ifHave: true
      },
      columns: [
        {
          name: '转赠编号',
          key: 'id',
        },
        {
          title: '图片',
          key: 'imageUrl',
        },
        {
          title: '名称',
          key: 'name',
        },
        {
          title: '转赠方',
          key: 'userName',
        },
        {
          title: '转赠手机号',
          key: 'phone',
        },
        {
          title: '转赠时间',
          key: 'dateTime',
        },
        {
          title: '操作',
          key: 'action',
        },
      ]
    };
  },

  mounted() {

  },

  methods: {
    /**
     * 进行获取
     */
    getLog() {
      console.log("查询交易记录")
      if (this.phoneInput.length !== 11) {
        message.info("非法手机号")
        return;
      }
      this.Page.page = 0
      this.Page.ifHave = true
      this.transactionToUserList = []
      this.phone = this.phoneInput
      this.getTransactionToUserList()
    },
    /**
     * 获取转赠记录
     */
    getTransactionToUserList() {
      this.loading = true
      //发送请求
      this.axios({
        method: "get",
        url: global.baseUrl + "/v1/api/admin/goods/transactionToUserLog/" + this.phone + "/" + this.Page.page + "/" + this.Page.size,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log("查询用户转赠记录");
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.loading = false
          return;
        }
        if (res.status === 200) {
          if (res.data.data.length < this.Page.size) {
            this.Page.ifHave = false
          }
          this.transactionToUserList = res.data.data
          this.loading = false
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.loading = false;
      })
    },
    loadMore() {
      console.log("触底")
      if (!this.Page.ifHave) {
        message.info("没有更多了")
        return;
      }
      this.Page.page += 1;
      this.getTransactionToUserList();
    },
    cancelTrans(record) {
      this.cancelGoods = record
      this.showConfirmCancel = true
    },
    doCancel() {
      console.log("撤销转赠")
      //发送请求
      this.axios({
        method: "get",
        url: global.baseUrl + "/v1/api/admin/goods/cancelTransactionToUser/" + this.phone + "/" + this.cancelGoods.id,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.loading = false
          return;
        }
        if (res.status === 200) {
          this.loading = false
          message.success("撤销成功")
          this.getLog()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.loading = false;
      })
    }
  },

}
</script>

<style scoped>
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.goodsList {
  height: 550px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

/*滚动条样式*/
.goodsList::-webkit-scrollbar {
  width: 4px;
}

.goodsList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.goodsList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.input {
  height: 30px;
  width: 250px;
  margin: 20px;
}
</style>