<!--
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-31 15:12:23
 * @LastEditors: 赵卓轩
 * @LastEditTime: 2022-08-19 00:08:40
-->
<template>
  <div class="content">
    <div class="goodsList">
      <a-divider>所有藏品</a-divider>
      <div>
        <a-table :loading="loading" :pagination="false" :columns="columns" :data-source="goodsList">
          <template #headerCell="{ column }">
            <template v-if="column.key === 'id'">
              <span>
                藏品编号
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              {{ record.goods.id }}
            </template>
            <template v-if="column.key === 'imageUrl'">
              <a-image style="width:80px;height:auto" :src="record.imgUrl"></a-image>
            </template>
            <template v-if="column.key === 'name'">
              {{ record.goods.name }}
            </template>
            <template v-if="column.key === 'type'">
              {{ record.goods.goodsType }}
            </template>
            <template v-if="column.key === 'total'">
              {{ record.goods.total }}
            </template>
            <template v-if="column.key === 'remain'">
              {{ record.goods.remain }}
            </template>
            <template v-if="column.key === 'canMarket'">
              {{ record.goods.canMarket ? '是' : '否' }}
            </template>
            <template v-if="column.key === 'minMarketPrice'">
              {{ record.goods.minMarketPrice || '暂无' }}
            </template>
            <template v-if="column.key === 'action'">
              <div class="btn-row">
                <a-button @click="dropSomeGoods(record.goods)" type="primary" ghost size="small">空投藏品</a-button>
                <a-button @click="dropGoods(record.goods)" type="primary" ghost size="small">批量空投</a-button>
                <a-button @click="setCanMarketModal(record.goods.id, record.goods.canMarket)" type="primary" ghost
                  size="small">设置流转状态</a-button>
              </div>
              <div class="btn-row">
                <a-button @click="setMinPriceModal(record.goods.id, record.goods.minMarketPrice)" type="primary" ghost
                  size="small">设置最低挂单价</a-button>
                <a-button @click="setLabelModal(record.goods.id)" type="primary" ghost size="small">设置标签</a-button>
                <a-button @click="setNewestPriceModal(record.goods.id)" type="primary" ghost size="small">设置最新价格
                </a-button>
              </div>
            </template>
          </template>
        </a-table>
      </div>
      <a-modal v-model:visible="showDrop" title="提示" cancelText="算了" okText="确认空投" :confirm-loading="confirmLoading"
        @ok="openDrop()">
        <p>空投藏品名称：{{ this.dropGoodsInfo.name }}</p>
        <div>
          发往用户手机号：
          <a-input class="input" v-model:value="phone" placeholder="手机号" />
        </div>
        <div>
          空投藏品序号：
          <a-select ref="select" v-model:value="sequenceNumber" style="width: 120px" :options="sequenceOptions"
            @focus="focus"></a-select>
        </div>
      </a-modal>
      <a-modal v-model:visible="showDropSome" title="提示" cancelText="算了" okText="确认空投" :confirm-loading="confirmLoading"
        @ok="openDropSome()">
        <p>空投藏品名称：{{ this.dropGoodsInfo.name }}</p>
        <div>
          发往用户手机号：
          <a-input class="input" v-model:value="phone" placeholder="手机号" />
        </div>
        <div>
          空投数量：
          <a-input-number class="input" v-model:value="number" keyboard="true" :min="0" />
        </div>
      </a-modal>
      <a-modal v-model:visible="showCanMarket" title="提示" cancelText="取消" okText="确认" :confirm-loading="confirmLoading"
        @ok="setCanMarket">
        <div>
          是否流转：
          <a-switch v-model:checked="isOnSell" checked-children="是" un-checked-children="否" />
        </div>
      </a-modal>
      <a-modal v-model:visible="showMinPrice" title="提示" cancelText="取消" okText="确认" :confirm-loading="confirmLoading"
        @ok="setMinPrice">
        <div>
          设置最低挂单价：
          <a-input class="input" v-model:value="minPrice" placeholder="价格" />
        </div>
      </a-modal>
      <a-modal v-model:visible="showNewestPrice" title="提示" cancelText="取消" okText="确认"
        :confirm-loading="confirmLoading" @ok="setNewestPrice">
        <p>当前最新成交价格：{{ this.currentPrice }}</p>
        <div>
          设置最新成交价格:
          <a-input class="input" v-model:value="newestPrice" placeholder="价格" />
        </div>
      </a-modal>
      <a-modal v-model:visible="showLabel" title="提示" cancelText="取消" okText="确认" :confirm-loading="confirmLoading"
        @ok="setLabel">
        <p>选择标签:</p>
        <template v-for="(tag, index) in labelList" :key="index">
          <a-checkable-tag :checked="selectedLabels.indexOf(tag.id) > -1"
            @change="checked => handleChange(tag.id, checked)">
            {{ tag.labelName }}
          </a-checkable-tag>
        </template>
      </a-modal>
      <a-modal v-model:visible="showConfirmDropSome" title="提示" cancelText="算了" okText="空投！"
        :confirm-loading="confirmLoading" @ok="doDropSome()">
        <p>空投藏品名称：<a-typography-text mark>{{ this.dropGoodsInfo.name }}</a-typography-text>
        </p>
        <p>用户手机号：<a-typography-text mark>{{ this.phone }}</a-typography-text>
        </p>
        <p>空投数量：<a-typography-text type="danger">{{ this.number }}</a-typography-text>
        </p>
        <p>
          <a-typography-text strong>空投藏品为重要操作，请谨慎核对，确认空投？</a-typography-text>
        </p>
      </a-modal>
      <a-modal v-model:visible="showConfirmDrop" title="提示" cancelText="算了" okText="空投！"
        :confirm-loading="confirmLoading" @ok="doDrop()">
        <p>空投藏品名称：<a-typography-text mark>{{ this.dropGoodsInfo.name }}</a-typography-text>
        </p>
        <p>空投藏品序号：<a-typography-text mark>{{ this.sequenceNumber }}</a-typography-text>
        </p>
        <p>用户手机号：<a-typography-text mark>{{ this.phone }}</a-typography-text>
        </p>
        <p>
          <a-typography-text strong>空投藏品为重要操作，请谨慎核对，确认空投？</a-typography-text>
        </p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import tokenUtils from '@/config/tokenUtils.js'
import global from "@/config/global.js"
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      goodsList: [],
      labelList: [],
      showDrop: false,
      showDropSome: false,
      showConfirmDrop: false,
      showConfirmDropSome: false,
      showCanMarket: false,
      showMinPrice: false,
      showNewestPrice: false,
      showLabel: false,
      goodsId: '',
      number: '',
      phone: '',
      sequenceNumber: 0,
      minPrice: 0,
      newestPrice: 0,
      currentPrice: 0,
      dropGoodsInfo: '',
      isOnSell: false,
      confirmLoading: false,
      goodsLoading: false,
      labelLoading: false,
      selectedLabels: [],
      initialLabels: [],
      columns: [
        {
          name: '藏品编号',
          key: 'id',
        },
        {
          title: '图片',
          key: 'imageUrl',
        },
        {
          title: '名称',
          key: 'name',
        },
        {
          title: '类别',
          key: 'type',
        },
        // {
        //   title: '介绍',
        //   key: 'introduction',
        // },
        {
          title: '总量',
          key: 'total',
        },
        {
          title: '余量',
          key: 'remain',
        },
        {
          title: '是否流转',
          key: 'canMarket'
        },
        {
          title: '最低挂单价',
          key: 'minMarketPrice'
        },
        {
          title: '操作',
          key: 'action',
        },
      ]
    };
  },
  computed: {
    loading() {
      return this.goodsLoading || this.labelLoading;
    }
  },
  mounted() {
    this.getGoodsList();
    this.getAllLabels();
  },

  methods: {
    /**
     * 获取藏品列表
     */
    getGoodsList() {
      this.goodsLoading = true;
      //发送请求
      this.axios({
        method: "get",
        url: global.baseUrl + "/v1/api/admin/goods/allGoods/",
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log("查询所有管理员信息");
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          return;
        }
        if (res.status === 200) {
          this.goodsList = res.data.data
        }
        this.goodsLoading = false;
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.goodsLoading = false;
      })
    },
    getAllLabels() {
      this.labelLoading = true;
      //发送请求
      this.axios({
        method: "get",
        url: global.baseUrl + "/v1/api/goodsLabel",
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          return;
        }
        if (res.status === 200) {
          this.labelList = res.data.data;
        }
        this.labelLoading = false;
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.labelLoading = false;
      })
    },
    handleChange(tag, checked) {
      const selectedTags = this.selectedLabels;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log(nextSelectedTags);
      this.selectedLabels = nextSelectedTags;
    },
    dropGoods(goods) {
      this.dropGoodsInfo = goods;
      this.goodsId = goods.id;
      this.showDropSome = true;
    },
    async dropSomeGoods(goods) {
      console.log("打开空投藏品弹窗")
      try {
        const existNumbers = await this.getExistNumber(goods.id);
        const existMap = new Map();
        existNumbers.data.data.forEach(item => {
          existMap.set(item, 1);
        })
        const notExistNumbers = await this.getNotExistNumber(goods.id);
        const availableNumbers = notExistNumbers.data.data.filter(item => !existMap.has(item));
        this.sequenceOptions = availableNumbers.map(item => {
          return {
            label: item,
            value: item
          }
        });
        // eslint-disable-next-line no-empty
      } catch (err) {
        message.warning(err);
      }
      this.dropGoodsInfo = goods
      this.goodsId = goods.id
      this.showDrop = true
    },
    setCanMarketModal(goodsId, canMarket) {
      this.goodsId = goodsId;
      this.isOnSell = canMarket;
      this.showCanMarket = true;
    },
    setMinPriceModal(goodsId, minMarketPrice) {
      this.minPrice = minMarketPrice || 0;
      this.goodsId = goodsId;
      this.showMinPrice = true;
    },
    setNewestPriceModal(goodsId) {
      this.newestPrice = 0;
      //发送请求
      this.axios({
        method: "GET",
        url: global.baseUrl + `/v1/api/market/newestPrice/${goodsId}`,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code === -1) {
          message.warning(res.data.message);
          return;
        }
        if (res.status === 200) {
          this.currentPrice = res.data.data;
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
      })
      this.goodsId = goodsId;
      this.showNewestPrice = true;
    },
    setLabelModal(goodsId) {
      this.selectedLables = [];
      //发送请求
      this.axios({
        method: "GET",
        url: global.baseUrl + `/v1/api/goodsLabel/labelName/${goodsId}`,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code === -1) {
          message.warning(res.data.message);
          return;
        }
        if (res.status === 200) {
          this.selectedLabels = res.data.data.map(item => item.id);
          this.initialLabels = this.selectedLabels.slice();
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
      })
      this.goodsId = goodsId;
      this.showLabel = true;
    },
    setCanMarket() {
      this.confirmLoading = true;
      //发送请求
      this.axios({
        method: "POST",
        url: global.baseUrl + `/v1/api/admin/goods/setCanMarket?can=${this.isOnSell}&goodsId=${this.goodsId}`,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code === -1) {
          message.warning(res.data.message);
          this.confirmLoading = false;
          this.showCanMarket = false;
          return;
        }
        if (res.status === 200) {
          this.confirmLoading = false;
          this.showCanMarket = false
          this.getGoodsList()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
      })
    },
    setMinPrice() {
      this.confirmLoading = true;
      //发送请求
      this.axios({
        method: "POST",
        url: global.baseUrl + `/v1/api/admin/goods/setMinPrice?goodsId=${this.goodsId}&price=${this.minPrice}`,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code === -1) {
          message.warning(res.data.message);
          this.confirmLoading = false;
          this.showMinPrice = false;
          return;
        }
        if (res.status === 200) {
          this.confirmLoading = false;
          this.showMinPrice = false
          this.getGoodsList()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
      })
    },
    setNewestPrice() {
      this.confirmLoading = true;
      const curtime = new Date().toLocaleString();
      const [date, second] = curtime.split(' ');
      const [year, month, day] = date.split('/');
      const formattedMonth = month.padStart(2, '0');
      const formattedDay = day.padStart(2, '0');
      const formattedDate = [year, formattedMonth, formattedDay].join('-');
      const timestamp = formattedDate + ' ' + second;
      //发送请求
      this.axios({
        method: "POST",
        url: global.baseUrl + `/v1/api/admin/goods/setNewestPrice?goodsId=${this.goodsId}&invalidateTime=${timestamp}&price=${this.newestPrice}`,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code === -1) {
          message.warning(res.data.message);
          this.confirmLoading = false;
          this.showNewestPrice = false;
          return;
        }
        if (res.status === 200) {
          this.confirmLoading = false;
          this.showNewestPrice = false
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
      })
    },
    setLabel() {
      this.confirmLoading = true;
      const selectedLabels = this.selectedLabels;
      const addLabels = [];
      const deleteLabels = [];
      for (let label of selectedLabels) {
        if (!this.initialLabels.includes(label)) {
          addLabels.push(label);
        }
      }
      for (let label of this.initialLabels) {
        if (!selectedLabels.includes(label)) {
          deleteLabels.push(label);
        }
      }
      console.log('addLabels', addLabels);
      console.log('deleteLabels', deleteLabels);
      const addPromises = addLabels.map(item => {
        return this.changeLabel(`/v1/api/admin/goods/bindGoodsLabel?goodsId=${this.goodsId}&labelId=${item}`);
      });
      const deletePromises = deleteLabels.map(item => {
        return this.changeLabel(`/v1/api/admin/goods/unbindGoodsLabel?goodsId=${this.goodsId}&labelId=${item}`);
      })
      const promises = [...addPromises, ...deletePromises];
      //发送请求
      if (promises.length) {
        Promise.all(promises).then(res => {
          if (res.find(item => item.data.code === -1)) {
            message.warning(res.data.message);
            this.confirmLoading = false;
            this.showLabel = false;
            return;
          } else {
            this.confirmLoading = false;
            this.showLabel = false;
          }
        }).catch(err => {
          message.warning(err.response.data.message);
          this.showLabel = false;
          this.confirmLoading = false;
        })
      } else {
        this.confirmLoading = false;
        this.showLabel = false;
      }
    },
    changeLabel(api) {
      return this.axios({
        method: "POST",
        url: global.baseUrl + api,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      })
    },
    openDrop() {
      this.showConfirmDrop = true
    },
    openDropSome() {
      this.showConfirmDropSome = true;
    },
    doDrop() {
      this.confirmLoading = true;
      this.axios({
        method: "POST",
        url: global.baseUrl + `/v1/api/admin/goods/dropGoodsWithSequenceNumber?goodsId=${this.goodsId}&phone=${this.phone}&sequenceNumber=${this.sequenceNumber}`,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code === -1) {
          console.log("到这里了")
          message.warning(res.data.message);
          this.confirmLoading = false;
          this.showConfirmDrop = false;
          return;
        }
        if (res.status === 200) {
          message.success("空投成功");
          this.confirmLoading = false;
          this.showDrop = false;
          this.showConfirmDrop = false
          this.getGoodsList()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
      })     
    },
    doDropSome() {
      console.log("请求空投")
      this.confirmLoading = true;
      //发送请求
      this.axios({
        method: "POST",
        url: global.baseUrl + "/v1/api/admin/goods/dropToUser?goodsId=" + this.goodsId + "&number=" + this.number + "&phone=" + this.phone,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code === -1) {
          console.log("到这里了")
          message.warning(res.data.message);
          this.confirmLoading = false;
          this.showConfirmDropSome = false;
          return;
        }
        if (res.status === 200) {
          message.success("空投成功");
          this.confirmLoading = false;
          this.showDropSome = false;
          this.showConfirmDropSome = false
          this.getGoodsList()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
      })
    },
    getExistNumber(goodsId) {
      return this.axios({
        method: "get",
        url: global.baseUrl + `/v1/api/admin/goods/sequenceNumber/exist/${goodsId}`,
        headers: {
          Authorization: "Bearer " + tokenUtils.getToken(),
        },
      })
    },
    getNotExistNumber(goodsId) {
      return this.axios({
        method: "get",
        url: global.baseUrl + `/v1/api/admin/goods/sequenceNumber/notExist/${goodsId}`,
        headers: {
          Authorization: "Bearer " + tokenUtils.getToken(),
        },
      })
    }
  },

}
</script>

<style scoped>
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.goodsList {
  height: 550px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

/*滚动条样式*/
.goodsList::-webkit-scrollbar {
  width: 4px;
}

.goodsList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.goodsList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.input {
  height: 30px;
  width: 250px;
  margin: 20px;
}

.btn-row {
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
</style>