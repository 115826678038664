<!--
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-30 16:13:47
 * @LastEditors: 沈为
 * @LastEditTime: 2022-08-01 15:59:48
-->
<template>
  <div class="content">
    <div class="loginWindow">
      NFT纪元中台管理系统登录
      <img class="logo" src="@/assets/logo.png" />
      <a-input class="input" v-model:value="phone" placeholder="手机号" />
      <a-input-password class="input" v-model:value="passw" placeholder="密码" />
      <a-button type="primary" class="loginBut" @click="login()" :disabled="disabled">登录</a-button>
    </div>
  </div>
</template>

<script>
import tokenUtils from '@/config/tokenUtils.js'
import global from "@/config/global.js"
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      phone:'',
      passw:'',
      disabled: false
    };
  },

  mounted() {
    
  },

  methods: {
      /**
       * 登录
       */
      login() {
        console.log("登录")
        console.log("手机号：" + this.phone)
        console.log("密码：" + this.passw)
        //判断手机号和密码
        if (this.phone.length !== 11) {
          message.info('非法手机号');
          return
        }
        if (this.passw.length === 0) {
          message.info('请输入密码');
          return
        }
        let data = {
          password: this.passw,
          phone: this.phone
        }
        this.disabled = true
        //发送请求
        this.axios.post(global.baseUrl + "/v1/login/password_phone", data).then((res) => {
              console.log(res);
              if(res.data.code==-1){
                message.warning(res.data.message);
                this.disabled = false
                return;
              }
              message.success("登录成功");
              tokenUtils.setToken(res.data.data.token)
              console.log("token", tokenUtils.getToken())
              global.user.phone = this.phone
              global.user.name = "暂无"
              localStorage.setItem("phone", this.phone)
              localStorage.setItem("name",this.name)
              this.disabled = false
              this.$router.push(
                {
                  name: 'theFrame'
                }
              )
            }).catch((res) => {
                message.warning(res.response.data.message);
            })
      }
  },

}
</script>

<style scoped>
.content{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo{
  height: 50px;
  width: 50px;
  margin: 20px;
}
.loginWindow{
  min-height: 300px;
  height:auto;
  width: 400px;
  padding: 30px;
  background-color: white;
	box-shadow: 1px 1px 6px rgba(120, 94, 150, 0.16);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.input{
  height: 30px;
  width: 250px;
  margin: 20px;
}
.loginBut{
  width: 100px;
  height: 30px;
  margin: 20px;
}

</style>
