<!--
 * @Description: 
 * @version: 1.0
 * @Author: 赵卓轩
 * @Date: 2022-08-14 15:51:46
 * @LastEditors: 赵卓轩
 * @LastEditTime: 2022-08-14 21:47:38
-->
<template>
    <div class="content">
        <div style="margin: 20px">
            输入用户手机号：
            <a-input class="input" v-model:value.lazy="phoneInput" placeholder="手机号" />
            <a-button type="primary" @click="getScore()">查询</a-button>
        </div>
        <a-spin tip="Loading..." :spinning="loading">
            <a-descriptions title="流转商城积分">
                <a-descriptions-item label="手机号码">{{
                        phoneParam
                }}</a-descriptions-item>
                <a-descriptions-item label="商城积分">{{
                        marketScore
                }}</a-descriptions-item>
                <a-descriptions-item label="修改积分">
                    <a-input-group compact>
                        <a-input v-model:value.lazy="marketScore" style="width: calc(100% - 200px)" />
                        <a-button type="primary" @click="changeScore">修改</a-button>
                    </a-input-group>
                </a-descriptions-item>
            </a-descriptions>
        </a-spin>
        <a-spin tip="Loading..." :spinning="invitationLoading">
            <a-descriptions title="邀新积分">
                <a-descriptions-item label="手机号码">{{
                        phoneParam
                }}</a-descriptions-item>
                <a-descriptions-item label="修改积分">
                    <a-input-group compact>
                        <a-input v-model:value.lazy="invitationScore" style="width: calc(100% - 200px)" />
                        <a-button type="primary" @click="changeInvitationScore">修改</a-button>
                    </a-input-group>
                </a-descriptions-item>
            </a-descriptions>
        </a-spin>
    </div>
</template>

<script>
import tokenUtils from "@/config/tokenUtils.js";
import global from "@/config/global.js";
import { message } from "ant-design-vue";

export default {
    name: "marketScoreManage",
    data() {
        return {
            phoneInput: "",
            marketScore: 0,
            invitationScore: 0,
            loading: false,
            invitationLoading: false,
            phoneParam: ""
        };
    },
    methods: {
        getScore() {
            this.marketScore = 0;
            this.loading = true;
            this.phoneParam = this.phoneInput;
            this.axios({
                method: "get",
                url: global.baseUrl + `/v1/api/admin/market/score/${this.phoneInput}`,
                headers: {
                    Authorization: "Bearer " + tokenUtils.getToken(),
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        this.marketScore = res.data.data?.score || 0;
                        this.loading = false;
                    } else {
                        message.warning(res.data.message);
                        this.loading = false;
                    }
                })
                .catch((res) => {
                    message.warning(res.response.data.message);
                    this.loading = false;
                });
        },
        changeScore() {
            this.loading = true;
            this.axios({
                method: "post",
                url: global.baseUrl + `/v1/api/admin/market/score?phone=${this.phoneInput}&score=${this.marketScore}`,
                headers: {
                    Authorization: "Bearer " + tokenUtils.getToken(),
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        message.info('流转积分修改成功');
                        this.loading = false;
                    } else {
                        message.warning(res.data.message);
                        this.loading = false;
                    }
                })
                .catch((res) => {
                    message.warning(res.response.data.message);
                    this.loading = false;
                });
        },
        changeInvitationScore() {
            this.invitationLoading = true;
            const dto = {
                mode: 'OVERRIDE',
                phone: this.phoneInput,
                value: this.invitationScore
            };
            this.axios({
                method: "post",
                url: global.baseUrl + `/v1/api/invite/override/update`,
                headers: {
                    Authorization: "Bearer " + tokenUtils.getToken(),
                },
                data: dto
            })
                .then((res) => {
                    if (res.status === 200) {
                        this.invitationLoading = false;
                        message.info('邀新数据修改成功');
                    } else {
                        message.warning(res.data.message);
                        this.invitationLoading = false;
                    }
                })
                .catch((res) => {
                    message.warning(res.response.data.message);
                    this.invitationLoading = false;
                });
        }
    },
};
</script>

<style scoped>
.content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input {
    height: 30px;
    width: 250px;
    margin: 20px;
}
</style>