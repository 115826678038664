<!--
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-30 16:13:47
 * @LastEditors: 赵卓轩
 * @LastEditTime: 2022-08-12 23:27:11
-->
<template>
  <div class="content">
    <a-divider>添加管理员</a-divider>
    <div class="addAdmin">
      <div style="margin:20px">
        添加管理员手机号：
        <a-input class="input" v-model:value="phone" placeholder="手机号" />
      </div>
      <div style="margin:20px">
        权限等级：
        <a-select
        ref="select"
        class="input"
        v-model:value="level"
        style="width: 300px"
        >
          <a-select-option value="READ">READ权限（只可查询数据）</a-select-option>
          <a-select-option value="WRITE">WRITE权限（可以空投，回收，撤销交易）</a-select-option>
          <a-select-option value="PARTNER">合作伙伴</a-select-option>
          <a-select-option value="ROOT" disabled>DROOT权限（WRITE+添加管理员）</a-select-option>
        </a-select>
      </div>
      <a-button class="button" type="primary" @click="addAdmin()">添加管理员</a-button>
    </div>
    <a-divider>查看管理员</a-divider>
    <div class="adminList">
      <a-table :loading="loading" :pagination="false" :columns="columns" :data-source="adminList">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
            <span>
              管理员编号
            </span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <span>
              <a-button danger @click="deleteAdmin(record.id)">移除管理员</a-button>
            </span>
          </template>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="showAdd" title="提示" cancelText="取消" okText="确认添加" :confirm-loading="confirmLoading" @ok="doAdd()">
      <p>所添加的管理员手机号：{{this.phone}}</p>
      <p>添加的权限：{{this.level}}</p>
      <p>添加管理员操作，请谨慎识别所给权限，确认添加？</p>
    </a-modal>
    <a-modal v-model:visible="showDelete" title="提示" :confirm-loading="confirmLoading" @ok="doDelete()">
      <p>移除管理员操作，确认移除？</p>
    </a-modal>
  </div>
</template>

<script>
import tokenUtils from '@/config/tokenUtils.js'
import global from "@/config/global.js"
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      phone: '',
      level: '',
      adminList: [],
      showAdd: false,
      showDelete: false,
      deleteId: '',
      confirmLoading: false,
      loading: false,
      columns: [
        {
          name: '管理员编号',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '用户编号',
          dataIndex: 'userId',
          key: 'userId',
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: '级别',
          dataIndex: 'level',
          key: 'level',
        },
        {
          title: '操作',
          key: 'action',
        },
      ]
    };
  },

  mounted() {
    this.getAdminList()
  },

  methods: {
    /**
     * 获取管理员列表
     */
    getAdminList() {
      this.loading = true
      //发送请求
      this.axios({
        method: "get",
        url: global.baseUrl + "/v1/api/admin/permission/all",
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log("查询所有管理员信息");
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.loading = false
          return;
        }
        if (res.status === 200) {
          this.adminList = res.data
          this.loading = false
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.loading = false;
      })
    },
    /**
     * 根据手机号添加管理员
     */
    addAdmin() {
      console.log("添加管理员")
      //判断手机号
      if (this.phone.length !== 11) {
        message.info('非法手机号');
        return
      }
      if (this.level.length === 0 ) {
        message.info('请选择权限等级');
        return
      }
      this.showAdd = true
    },
    /**
     * 请求添加管理员接口
     */
    doAdd(){
      this.confirmLoading = true;
      //发送请求
      this.axios({
        method: "POST",
        url: global.baseUrl + "/v1/api/admin/permission/addAdminUser?level=" + this.level + "&phone=" + this.phone,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.confirmLoading = false;
          return;
        }
        if (res.status === 200) {
          message.success("添加成功");
          this.confirmLoading = false;
          this.showAdd = false
          this.getAdminList()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
        this.showAdd = false
      })
    },
    /**
     * 根据id移除管理员
     * @param {int} id 移除的管理员的id 
     */
    deleteAdmin(id) {
      console.log("删除管理员")
      this.deleteId = id
      this.showDelete = true
    },
    /**
     * 请求移除管理员接口
     */
    doDelete(){
      this.confirmLoading = true;
      //发送请求
      this.axios({
        method: "POST",
        url: global.baseUrl + "/v1/api/admin/permission/removeAdminUser?adminUserId=" + this.deleteId,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.confirmLoading = false;
          return;
        }
        if (res.status === 200) {
          message.info("已删除")
          this.deleteId = ''
          this.confirmLoading = false;
          this.showDelete = false
          this.getAdminList()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
        this.showDelete = false
      })
    }
  },

}
</script>

<style scoped lang="scss">
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.addAdmin {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  height: auto;
  width: 100%;

  .input {
    height: 30px;
    width: 250px;
    // margin: 20px;
  }

  .button {
    width: 100px;
    height: 30px;
    margin: 20px;
  }
}

.adminList {
  height: 400px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
/*滚动条样式*/
.adminList::-webkit-scrollbar {
width: 4px;
}
.adminList::-webkit-scrollbar-thumb {
border-radius: 10px;
box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
background: rgba(0,0,0,0.2);
}
.adminList::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
border-radius: 0;
background: rgba(0,0,0,0.1);
}

</style>
