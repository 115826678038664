/*
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-31 10:37:28
 * @LastEditors: 沈为
 * @LastEditTime: 2022-08-01 15:59:29
 */
// 认证令牌
const TokenKey = 'token';
export function getToken() {
	return localStorage.getItem(TokenKey)
}

export function setToken(token) {
	return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
	return localStorage.removeItem(TokenKey)
}

export default{
	getToken,
	setToken,
	removeToken
}