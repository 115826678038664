<!--
 * @Descripttion: 
 * @version: 
 * @Author: 沈为
 * @Date: 2022-07-31 15:12:34
 * @LastEditors: 沈为
 * @LastEditTime: 2022-08-02 13:00:38
-->
<template>
  <div class="content">
    <div style="margin:20px">
      输入用户手机号：
      <a-input class="input" v-model:value="phoneInput" placeholder="手机号" />
      <a-button type="primary" @click="getGoods()">查询</a-button>
    </div>
    <div class="goodsList">
      <a-divider>用户普通藏品</a-divider>
      <div>
        <a-table :loading="loading" :pagination="false" :columns="columns" :data-source="normalGoodsList">
          <template #headerCell="{ column }">
            <template v-if="column.key === 'id'">
              <span>
                藏品编号
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              {{record.goodsVo.goods.id}}
            </template>
            <template v-if="column.key === 'imageUrl'">
              <a-image style="width:80px;height:auto" :src="record.goodsVo.imgUrl"></a-image>
            </template>
            <template v-if="column.key === 'name'">
              {{record.goodsVo.goods.name}}
            </template>
            <template v-if="column.key === 'type'">
              {{record.goodsVo.goods.goodsType}}
            </template>
            <template v-if="column.key === 'total'">
              {{record.relationVos.length}}
            </template>
            <template v-if="column.key === 'action'">
              <span>
                <a-button @click="checkGoods(record)" type="primary" ghost>回收与冷却</a-button>
              </span>
            </template>
          </template>
        </a-table>
      </div>
    </div>
    <div class="goodsList">
      <a-divider>用户盲盒藏品</a-divider>
      <div>
        <a-table :loading="loading" :pagination="false" :columns="columns" :data-source="bindBoxGoodsList">
          <template #headerCell="{ column }">
            <template v-if="column.key === 'id'">
              <span>
                藏品编号
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              {{record.goodsVo.goods.id}}
            </template>
            <template v-if="column.key === 'imageUrl'">
              <a-image style="width:80px;height:auto" :src="record.goodsVo.imgUrl"></a-image>
            </template>
            <template v-if="column.key === 'name'">
              {{record.goodsVo.goods.name}}
            </template>
            <template v-if="column.key === 'type'">
              {{record.goodsVo.goods.goodsType}}
            </template>
            <template v-if="column.key === 'total'">
              {{record.relationVos.length}}
            </template>
            <template v-if="column.key === 'action'">
              <span>
                <a-button @click="checkGoods(record)" type="primary" ghost>回收与冷却</a-button>
              </span>
            </template>
          </template>
        </a-table>
      </div>
    </div>
    <!-- 操作弹窗 -->
    <a-modal v-model:visible="showAction" title="操作藏品">
      <div class="info">
        <a-image style="width:90px;height:auto" :src="goodsRecord.goodsVo.imgUrl"></a-image>
        <a-typography-text strong style="margin-top:20px;margin-bottom:50px">{{goodsRecord.goodsVo.goods.name}}</a-typography-text>
      </div>
      <div style="margin:20px" v-for="(item, index) in goodsRecord.relationVos" :key="index">
        藏品序号：<a-typography-text strong>{{item.sequenceNumber}}</a-typography-text>
        <a-button style="margin-left: 20px;float: right;" type="primary" ghost @click="openCalm(item)">清除冷却时间</a-button>
        <a-button style="margin-left: 20px;float: right;" type="danger" ghost @click="openRecycle(item)">回收</a-button>
      </div>
      <template #footer/>
    </a-modal>
    <a-modal v-model:visible="showRecycle" title="提示" cancelText="算了" okText="回收！" :confirm-loading="confirmLoading" @ok="doRecycle()">
      <p>用户手机号：<a-typography-text mark>{{this.phone}}</a-typography-text></p>
      <p>回收藏品名称：<a-typography-text mark>{{this.goodsRecord.goodsVo.name}}</a-typography-text></p>
      <p>藏品序号：<a-typography-text type="danger">{{this.operateItem.sequenceNumber}}</a-typography-text></p>
      <p><a-typography-text strong>回收藏品为重要操作，请谨慎核对，确认回收？</a-typography-text></p>
    </a-modal>
    <a-modal v-model:visible="showCalm" title="提示" cancelText="算了" okText="清除冷却！" :confirm-loading="confirmLoading" @ok="doCalm()">
      <p>用户手机号：<a-typography-text mark>{{this.phone}}</a-typography-text></p>
      <p>操作藏品名称：<a-typography-text mark>{{this.goodsRecord.goodsVo.name}}</a-typography-text></p>
      <p>藏品序号：<a-typography-text type="danger">{{this.operateItem.sequenceNumber}}</a-typography-text></p>
      <p><a-typography-text strong>该操作将减少藏品的冷却时间12小时，确认清除？</a-typography-text></p>
    </a-modal>
  </div>
</template>

<script>
import tokenUtils from '@/config/tokenUtils.js'
import global from "@/config/global.js"
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      /**
       * 设置这个变量会防止输入框改变但是藏品还是上一次查询的会造成的错误
       */
      phoneInput: '',
      phone: '',
      bindBoxGoodsList: [],
      normalGoodsList: [],
      goodsRecord: '',
      operateItem:'',
      loading: false,
      showAction: false,
      showCalm: false,
      showRecycle: false,
      confirmLoading: false,
      columns: [
        {
          name: '藏品编号',
          key: 'id',
        },
        {
          title: '图片',
          key: 'imageUrl',
        },
        {
          title: '名称',
          key: 'name',
        },
        {
          title: '类别',
          key: 'type',
        },
        {
          title: '持有数量',
          key: 'total',
        },
        {
          title: '操作',
          key: 'action',
        },
      ]
    };
  },

  mounted() {
    
  },

  methods: {
    /**
     * 查询手机号对应的藏品
     */
    getGoods(){
      console.log("查询藏品")
      if(this.phoneInput.length !== 11){
        message.info("非法手机号")
        return;
      }
      this.phone = this.phoneInput
      this.getBindBoxGoods();
      this.getNormalGoods();
    },
    /**
     * 获取盲盒内容物藏品列表
     */
    getBindBoxGoods() {
      console.log("查询盲盒藏品")
      if(this.phone.length !== 11){
        message.info("非法手机号")
        return;
      }
      this.loading = true
      //发送请求
      this.axios({
        method: "get",
        url: global.baseUrl + "/v1/api/admin/goods/allBlindBoxGoods/" + this.phone,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log("查询盲盒藏品信息");
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.loading = false
          return;
        }
        if (res.status === 200) {
          this.bindBoxGoodsList = res.data.data
          this.loading = false
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.loading = false
      })
    },
    /**
     * 获取普通藏品列表
     */
    getNormalGoods() {
      console.log("查询普通藏品")
      if(this.phone.length !== 11){
        message.info("非法手机号")
        return;
      }
      this.loading = true
      //发送请求
      this.axios({
        method: "get",
        url: global.baseUrl + "/v1/api/admin/goods/allGift/" + this.phone,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log("查询普通藏品信息");
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.loading = false
          return;
        }
        if (res.status === 200) {
          this.normalGoodsList = res.data.data
          this.loading = false
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.loading = false
      })
    },
    /**
     * 打开回收与冷却弹窗
     * @param {object} record 传入某一条记录包含的relationVos，并存在变量中
     */
    checkGoods(record){
      console.log("打开回收与冷却弹窗"+record)
      this.goodsRecord = record
      this.showAction = true
    },
    /**
     * 打开清除冷却弹窗
     * @param {object} item 存sequenceNumber和relationId
     */
    openCalm(item){
      console.log("打开冷却弹窗")
      this.operateItem = item
      this.showCalm = true
    },
    /**
     * 请求清除冷却接口
     */
    doCalm(){
      console.log("确认清除冷却")
      this.confirmLoading = true;
      //发送请求
      this.axios({
        method: "POST",
        url: global.baseUrl + "/v1/api/admin/goods/unCalmDownGoods?phone=" + this.phone + "&relationId=" + this.operateItem.relationId,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.confirmLoading = false;
          this.showCalm = false;
          return;
        }
        if (res.status === 200) {
          message.success("清除成功");
          this.confirmLoading = false;
          this.showCalm = false;
          this.getGoods()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
      })
    },
    /**
     * 
     * @param {object} item 存sequenceNumber和relationId
     */
    openRecycle(item){
      console.log("打开回收弹窗")
      this.operateItem = item
      this.showRecycle = true
    },
    /**
     * 请求回收接口
     */
    doRecycle(){
      console.log("确认回收")
      this.confirmLoading = true;
      //发送请求
      this.axios({
        method: "POST",
        url: global.baseUrl + "/v1/api/admin/goods/recycleGoods?phone=" + this.phone + "&relationId=" + this.operateItem.relationId,
        headers: {
          'Authorization': 'Bearer ' + tokenUtils.getToken()
        }
      }).then((res) => {
        console.log(res);
        if (res.data.code == -1) {
          message.warning(res.data.message);
          this.confirmLoading = false;
          this.showRecycle = false;
          return;
        }
        if (res.status === 200) {
          message.success("回收成功");
          this.confirmLoading = false;
          this.showRecycle = false;
          this.showAction = false
          this.getGoods()
        }
      }).catch((res) => {
        message.warning(res.response.data.message);
        this.confirmLoading = false;
      })
    }

  },

}
</script>

<style scoped>
.content{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.goodsList{
  height: auto;
  max-height: 500px;
  margin-bottom: 50px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}
/*滚动条样式*/
.goodsList::-webkit-scrollbar {
width: 4px;
}
.goodsList::-webkit-scrollbar-thumb {
border-radius: 10px;
box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
background: rgba(0,0,0,0.2);
}
.goodsList::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
border-radius: 0;
background: rgba(0,0,0,0.1);
}
.input {
  height: 30px;
  width: 250px;
  margin: 20px;
}
.info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>